import BButton from 'components/common/BButton';
import TranslatedLink from 'components/common/TranslatedLink';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

interface HomeSectionTitleProps {
  title: string;
  description?: string;
  href: string;
}

const HomeSectionTitle: FunctionComponent<HomeSectionTitleProps> = ({
  title,
  description,
  href,
}) => {
  const { t } = useTranslation('home');
  return (
    <div className="items-centers flex flex-col justify-between md:flex-row">
      <div>
        <h2 className="text-medium text-xl">{title}</h2>
        {description ? (
          <div className="mt-1 text-slate-500">{description}</div>
        ) : null}
      </div>
      <TranslatedLink href={href} prefetch={false}>
        <a className="-ml-2 md:m-0">
          <BButton variant="secondary" priority="text" className="underline">
            {t('seeAllLinkText')}
          </BButton>
        </a>
      </TranslatedLink>
    </div>
  );
};

export default HomeSectionTitle;
