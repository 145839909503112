import ResponsiveCarousel from 'components/common/ResponsiveCarousel';
import { useLocale } from 'hooks/useLocale';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { linkService } from 'services/LinkService';
import { VehicleSummary } from 'types/vehicle.type';
import VehicleCard from '../Vehicle/VehicleCard';
import HomeSectionTitle from './HomeSectionTitle';

interface HomeVehiclesSectionProps {
  vehicles: VehicleSummary[];
}

const HomeVehiclesSection: FunctionComponent<HomeVehiclesSectionProps> = ({
  vehicles,
}) => {
  const { t } = useTranslation('home');
  const { locale } = useLocale();
  const actionLink = linkService.generateCategoryLink({ locale });

  return vehicles?.length > 0 ? (
    <section className="mt-16">
      <HomeSectionTitle
        title={t('vehiclesSection.title')}
        description={t('vehiclesSection.description')}
        href={actionLink}
      />
      <ResponsiveCarousel className="mt-10">
        {vehicles?.map((vehicle, idx) => (
          <VehicleCard {...vehicle} key={`vehicles-${idx}`} />
        ))}
      </ResponsiveCarousel>
    </section>
  ) : null;
};

export default HomeVehiclesSection;
