import BButton from 'components/common/BButton';
import TranslatedLink from 'components/common/TranslatedLink';
import PartnerIllustration from 'components/Icons/illustrations/home/partner.svg';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

const HomePartnerSection: FunctionComponent = () => {
  const { t } = useTranslation('home');

  return (
    <section className="mt-16 flex flex-col-reverse items-center justify-between gap-4 sm:flex-row">
      <div className="flex flex-col">
        <div className="text-2xl font-semibold">
          {t('partnerSection.title')}
        </div>
        <div className="mt-2 text-lg font-medium text-slate-500">
          {t('partnerSection.description')}
        </div>
        <TranslatedLink href="/partner">
          <a className="self-center md:self-start">
            <BButton className="mt-4 text-lg">
              {t('partnerSection.cta')}
            </BButton>
          </a>
        </TranslatedLink>
      </div>
      <PartnerIllustration className="w-1/2 max-w-md" />
    </section>
  );
};

export default HomePartnerSection;
