import ResponsiveCarousel from 'components/common/ResponsiveCarousel';
import { VehicleType } from 'enums/vehicleTypes';
import { useLocale } from 'hooks/useLocale';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { linkService } from 'services/LinkService';
import HomeSectionTitle from './HomeSectionTitle';
import HomeVehicleTypeCard from './HomeVehicleTypeCard';

const HomeVehicleTypesSection: FunctionComponent = () => {
  const { t } = useTranslation('home');
  const { locale } = useLocale();
  const actionLink = linkService.generateCategoryLink({ locale });

  const vehicleTypes = [
    {
      ...linkService.getCategoryLinkOption(VehicleType.YACHT, locale),
      imageUrl:
        'https://cdn.teknevia.com/storefront/homepage/rental-motoryatch.png',
    },
    {
      ...linkService.getCategoryLinkOption(VehicleType.INVITATION_BOAT, locale),
      imageUrl:
        'https://cdn.teknevia.com/storefront/homepage/rental-invitation-boat.png',
    },
    {
      ...linkService.getCategoryLinkOption(VehicleType.GULET, locale),
      imageUrl:
        'https://cdn.teknevia.com/storefront/homepage/rental-gulet.avif',
    },
    {
      ...linkService.getCategoryLinkOption(VehicleType.SAIL_BOAT, locale),
      imageUrl:
        'https://cdn.teknevia.com/storefront/homepage/rental-sail-boat.avif',
    },
    {
      ...linkService.getCategoryLinkOption(VehicleType.SPEED_BOAT, locale),
      imageUrl:
        'https://cdn.teknevia.com/storefront/homepage/rental-speed-boat.avif',
    },
    {
      ...linkService.getCategoryLinkOption(null, locale, 'BOATRENTAL'),
      imageUrl: 'https://cdn.teknevia.com/storefront/homepage/rental-boat.jpg',
    },
    {
      ...linkService.getCategoryLinkOption(null, locale, 'YACHTRENTAL'),
      imageUrl: 'https://cdn.teknevia.com/storefront/homepage/rental-yacht.jpg',
    },
  ];
  return (
    <section className="mt-16">
      <HomeSectionTitle
        title={t('vehicleTypesSection.title')}
        href={actionLink}
      />
      <ResponsiveCarousel className="mt-10">
        {vehicleTypes.map((type, idx) => (
          <HomeVehicleTypeCard
            key={`organizations-${idx}`}
            name={type.label}
            url={type.value}
            imageUrl={type.imageUrl}
          />
        ))}
      </ResponsiveCarousel>
    </section>
  );
};

export default HomeVehicleTypesSection;
