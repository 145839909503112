import classNames from 'classnames';
import BImage from 'components/common/BImage';
import Link from 'next/link';
import { FunctionComponent } from 'react';

interface HomeVehicleTypeCardProps {
  name: string;
  imageUrl: string;
  url: string;
  className?: string;
}

const HomeVehicleTypeCard: FunctionComponent<HomeVehicleTypeCardProps> = ({
  name,
  imageUrl,
  url,
  className,
}) => {
  return (
    <div className={classNames(className)}>
      <Link href={url}>
        <a>
          <BImage
            className="rounded-md object-cover"
            src={imageUrl}
            layout="fixed"
            width={300}
            height={240}
            alt={name + 'typed vehicle image'}
          />
          <div className="text-medium mt-1 text-center text-lg">{name}</div>
        </a>
      </Link>
    </div>
  );
};

export default HomeVehicleTypeCard;
