import classNames from 'classnames';
import { Children, FunctionComponent, ReactNode } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

interface ResponsiveCarouselProps {
  className?: string;
  children: ReactNode[];
  pagination?: SwiperProps['pagination'];
  spacing?: string;
  slidesPerView?: SwiperProps['slidesPerView'];
  spaceBetween?: SwiperProps['spaceBetween'];
  onSlideChange?: () => void;
}

const ResponsiveCarousel: FunctionComponent<ResponsiveCarouselProps> = ({
  className,
  children,
  pagination = false,
  slidesPerView = 'auto',
  spaceBetween = undefined,
  spacing = 'px-3',
  onSlideChange,
}) => {
  const modules = [...(pagination ? [Pagination] : [])];

  return (
    <div className={classNames('responsive-carousel w-full', className)}>
      <Swiper
        modules={modules}
        pagination={
          pagination
            ? {
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 5,
              }
            : false
        }
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        onSlideChange={onSlideChange}
        // breakpoints={breakpoints}
      >
        {Children.map(children, (child) => (
          <SwiperSlide
            className={classNames('mx-auto first:pl-0 last:pr-0', spacing)}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ResponsiveCarousel;
