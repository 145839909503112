import classNames from 'classnames';
import BImage from 'components/common/BImage';
import TranslatedLink from 'components/common/TranslatedLink';
import { Locale } from 'enums/locale';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { Organization } from 'types/organization.type';

interface OrganizationCardProps extends Organization {
  className?: string;
  prioritizeImg?: boolean;
}

const OrganizationCard: FunctionComponent<OrganizationCardProps> = ({
  title,
  description,
  imageUrl,
  slug,
  className,
  prioritizeImg = false,
}) => {
  const { locale } = useRouter();

  return (
    <div className={classNames('w-40 md:w-72', className)}>
      <TranslatedLink href={`/hizmetler/${slug[locale as Locale]}`}>
        <a>
          <BImage
            className="rounded-md"
            src={imageUrl || ''}
            layout="responsive"
            width={281}
            height={434}
            alt="name"
            priority={prioritizeImg}
          />
          <div className="text-medium mt-1 text-base md:text-lg">{title}</div>
          <div className="mt-1 text-sm text-slate-500 md:text-base">
            {description}
          </div>
        </a>
      </TranslatedLink>
    </div>
  );
};

export default OrganizationCard;
