import ResponsiveCarousel from 'components/common/ResponsiveCarousel';
import Calendar from 'components/Icons/banners/calendar.svg';
import Shield from 'components/Icons/banners/shield.svg';
import Wallet from 'components/Icons/banners/wallet.svg';
import useTranslation from 'next-translate/useTranslation';
import HomeBanner from './HomeBanner';

function HomeBanners() {
  const { t } = useTranslation('home');
  return (
    <section className="mt-12">
      <ResponsiveCarousel>
        <HomeBanner
          title={t('banners.first.title')}
          description={t('banners.first.description')}
          icon={<Calendar />}
        />
        <HomeBanner
          title={t('banners.second.title')}
          description={t('banners.second.description')}
          icon={<Wallet />}
        />
        <HomeBanner
          title={t('banners.third.title')}
          description={t('banners.third.description')}
          icon={<Shield />}
        />
      </ResponsiveCarousel>
    </section>
  );
}

export default HomeBanners;
