import ResponsiveCarousel from 'components/common/ResponsiveCarousel';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { Organization } from 'types/organization.type';
import OrganizationCard from '../Organizations/OrganizationCard';
import HomeSectionTitle from './HomeSectionTitle';

interface HomeOrganizationsSectionProps {
  organizations: Organization[];
}

const HomeOrganizationsSection: FunctionComponent<
  HomeOrganizationsSectionProps
> = ({ organizations }) => {
  const { t } = useTranslation('home');
  return (
    <section className="mt-16">
      <HomeSectionTitle
        title={t('organizationsSection.title')}
        description={t('organizationsSection.description')}
        href="/hizmetler"
      />
      <ResponsiveCarousel spacing="px-1 md:px-3 mt-10">
        {organizations.map((org, idx) => (
          <OrganizationCard key={`organizations-${idx}`} {...org} />
        ))}
      </ResponsiveCarousel>
    </section>
  );
};

export default HomeOrganizationsSection;
