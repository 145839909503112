import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

interface HomeBannerProps {
  title: string;
  icon: ReactNode;
  description: string;
  wrapperClass?: string;
}

const HomeBanner: FunctionComponent<HomeBannerProps> = ({
  title,
  icon,
  description,
  wrapperClass,
}) => {
  return (
    <div
      className={classNames(
        'flex w-96 items-center rounded-md border p-4 shadow-sm lg:w-80 xl:w-96',
        wrapperClass
      )}
    >
      <div className="mr-5">{icon}</div>
      <div>
        <div className="mb-3 text-lg">{title}</div>
        <div className="text-base text-slate-500">{description}</div>
      </div>
    </div>
  );
};

export default HomeBanner;
